exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-babyl-in-the-news-js": () => import("./../../../src/pages/babyl-in-the-news.js" /* webpackChunkName: "component---src-pages-babyl-in-the-news-js" */),
  "component---src-pages-blog-body-image-js": () => import("./../../../src/pages/blog/body-image.js" /* webpackChunkName: "component---src-pages-blog-body-image-js" */),
  "component---src-pages-blog-covid-19-js": () => import("./../../../src/pages/blog/covid-19.js" /* webpackChunkName: "component---src-pages-blog-covid-19-js" */),
  "component---src-pages-blog-digital-health-js": () => import("./../../../src/pages/blog/digital-health.js" /* webpackChunkName: "component---src-pages-blog-digital-health-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-kwibuka-26-js": () => import("./../../../src/pages/blog/kwibuka-26.js" /* webpackChunkName: "component---src-pages-blog-kwibuka-26-js" */),
  "component---src-pages-blog-telemedicine-new-consulting-js": () => import("./../../../src/pages/blog/telemedicine-new-consulting.js" /* webpackChunkName: "component---src-pages-blog-telemedicine-new-consulting-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-clinical-team-js": () => import("./../../../src/pages/clinical-team.js" /* webpackChunkName: "component---src-pages-clinical-team-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-facilities-js": () => import("./../../../src/pages/facilities.js" /* webpackChunkName: "component---src-pages-facilities-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-center-js": () => import("./../../../src/pages/press-center.js" /* webpackChunkName: "component---src-pages-press-center-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-what-we-treat-tsx": () => import("./../../../src/pages/what-we-treat.tsx" /* webpackChunkName: "component---src-pages-what-we-treat-tsx" */)
}

